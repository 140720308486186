
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import t from '../texts'

export default function DialogSplash(props) {


  const languageCode = (window.location.search.indexOf('lang=he') > -1) ? "he" : "en";

  return <div className={'dialog'} style={{ color: '#fff' }}>

    <Box sx={{ maxWidth: 600, margin: '0 auto', paddingTop: 5 }}>
      <Box mb={2} p={2} >
        <Typography variant="body1" sx={{
          fontSize: '1.5rem', fontSize: '2.5vh', fontWeight: 400,
          '@media (max-width: 30ch)': {
            maxWidth: '18ch',
            margin: '0 auto'
          }
        }} gutterBottom>{t('INTRO_1')}</Typography>
        <Typography variant="body1" sx={{ fontSize: '1.5rem', fontSize: '2.5vh', fontWeight: 400 }} gutterBottom>{t('INTRO_2')}</Typography>
      </Box>
      <Typography variant="h1" my={4} sx={{ fontSize: '5rem', fontSize: '10vh', paddingRight: '1vw', paddingLeft: '1vw', fontWeight: 900 }}>{t('INTRO_3')}</Typography>

      <Box my={4}>
        <Button variant={"contained"} size="large" sx={{ minWidth: 200, fontSize: 30, fontSize: '4vh' }}

          onClick={props.onNext}  >{t('BTN_NEXT')}</Button>
      </Box>

      <Box pt={3}>
        {languageCode == 'en' && <a style={{ color: 'white', textDecoration: 'none', fontSize: '1.5rem' }} href='/?lang=he'>עברית</a>}
        {languageCode == 'he' && <a style={{ color: 'white', textDecoration: 'none', fontSize: '1.5rem' }} href='/?lang=en'>English</a>}
      </Box>
    </Box>
  </div>
}