import React from 'react'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { HORN_TYPES_DATA, HORN_TYPES } from '../constants';

import CountUp from 'react-countup';

import t from '../texts'

export default function DialogSelectHorn(props) {

  const { selectedHorn, setSelectedHorn } = props;

  const prevSelectedHorn = React.useRef()

  const onSelectedHorn = (id) => {
    prevSelectedHorn.current = selectedHorn
    setSelectedHorn(id)
  }

  const renderOption = (id) => {
    const item = HORN_TYPES_DATA[id];
    return <div key={id} onClick={() => { onSelectedHorn(id) }}><div className={selectedHorn === id ? 'selection selected' : 'selection'}>
      <div className="ring"></div>
      <div className="img" alt={id} style={{ background: `url('${item.image}') no-repeat center center`, backgroundSize: 'cover' }}></div>

    </div></div>
  };

  return <div className={'dialog '} style={{ color: '#fff' }}>

    <Box
      sx={{
        maxWidth: 800, margin: '0 auto', paddingTop: '10vh',
        '@media (max-width:600px)': {
          paddingTop: '3vh'
        },
      }}>
      <Typography variant="h1" style={{ fontSize: '7rem', fontSize: '15vh', fontWeight: 900 }}>{t('SELECT_MODE')}</Typography>



      <Box display="flex" justifyContent="space-between" sx={{
        margin: '10vh 30px',
        '@media (max-width:600px)': {
          margin: '6vh 30px',
        },
        '& .selection .img': {
          transition: '.5s',
          width: 150, height: 150, maxWidth: '20vw', maxHeight: '20vw',


          position: 'absolute',
          left: 0,
          right: 0
        },
        '& .selection': {
          borderRadius: '50%', background: '#eee', width: 150, height: 150, maxWidth: '20vw', maxHeight: '20vw',

          WebkitUserSelect: 'none',
          WebkitTouchCallout: 'none',
          WebkitTapHighlightColor: 'transparent',
          //boxShadow: '0 0 0px #ff9500',
          cursor: 'pointer',
          position: 'relative',
          transition: '.5s'
        },
        '& .selection .ring': {
          margin: '-7px',
          display: 'block',
          borderRadius: '50%',
          width: '100%',
          height: '100%',
          position: 'absolute',
          opacity: 0,
          border: '0px solid #ff9500dd',
        },
        '& .selected .ring': {
          opacity: 1,
          border: '7px solid #ff9500dd',
        },
        '& .selected .img': {
          transform: 'scale(1.1)',
        },
        '& .selection:hover': {
          transform: 'rotateZ(5deg)',

        },
      }}>
        {renderOption(HORN_TYPES.MOTORBIKE)}
        {renderOption(HORN_TYPES.CAR)}
        {renderOption(HORN_TYPES.TRUCK)}

      </Box>

      <Box mb={4} px={2}>
        <Typography variant="body1"
          sx={{
            fontSize: '2rem', fontSize: '3.7vh', fontWeight: 300,
            '@media (max-width: 600px)': {
              maxWidth: '18ch',
              margin: '0 auto'
            }
          }}>{t('HONK_RANGE_WILL_BE')}
          <b>    <CountUp duration={0.5} delay={0} start={HORN_TYPES_DATA[prevSelectedHorn?.current || selectedHorn].metersRadius} end={HORN_TYPES_DATA[selectedHorn].metersRadius} />  {t('METERS')}</b></Typography>

      </Box>

      <Box my={4}>
        <Button variant={selectedHorn ? "contained" : "outlined"}

          onClick={props.onNext}
          size="large" sx={{ minWidth: 200, fontSize: 30 }}
        >{t('CONTINUE')}</Button>
      </Box>
    </Box>
  </div>
}