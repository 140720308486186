import { useMapEvent } from 'react-leaflet'
 
export default function MapClick(props) {
  
  const map = useMapEvent('click', (e) => {
    
    if (props.onLocationClicked === null) return;

    map.flyTo([e.latlng.lat, e.latlng.lng], 18)
    
    props.onLocationClicked(e.latlng)
 
  }) 
  return null
}

