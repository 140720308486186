import React from 'react'
import CountUp from 'react-countup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReplayIcon from '@mui/icons-material/Replay';
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';
import ShareButtons from './ShareButtons';

import { TEXTS_RESULTS } from '../texts'

import t from '../texts.js'
export default function DialogResult(props) {

  const { results } = props;
  const [showShareButtons, setShowShareButtons] = React.useState()

  const onShare = () => {

    setShowShareButtons(v => !v);
    if (navigator.share) {
      navigator.share({
        title: t('PAGE_TITLE'),
        url: 'https://carhorn.yaronshemesh.com/'
      });
    }
  }

  const showResults = results?.affectedEstimate != null;

  return <div className={'dialog dialog-bg'} onClose={props.handleClose} open={props.isOpen}>

    <Box sx={{ maxWidth: 400, margin: '0 auto', padding: '5vh 0 2vh 0' }}>
      <div style={{ marginBottom: '4vh' }} >

        <Typography variant="h3" style={{ fontSize: '3rem', padding: '10px 15px' }} className="text-bg">{t('RESULT_LINE_HONK_COULD_ANNOY')}</Typography>
        <Typography variant="h2" style={{ fontSize: '6rem', fontWeight: 900 }} className="text-stroke">
          {showResults ? <CountUp duration={5} delay={0} end={results?.affectedEstimate} /> : t('RESULT_LINE_INPROGRESS')}
        </Typography>
        <Typography variant="h3" style={{ fontSize: '3rem', padding: '10px 15px' }} className="text-bg">{t('RESULT_LINE_RESIDENTS')}</Typography>

      </div>

      {showResults && <Box className="show-buttons-delay" display="flex" justifyContent="space-between" sx={{ direction: 'ltr', maxWidth: '95vw', margin: '0 auto' }}>
        <Button variant="contained" disableRipple disableElevation fullWidth onClick={props.onAbout} endIcon={<InfoIcon />} color="secondary">{t('RESULT_BTN_HOW')}</Button>
        <Button variant="contained" disableRipple disableElevation fullWidth className="alt" sx={{ margin: '0 20px' }} onClick={props.onPrev} endIcon={<ReplayIcon />} color="primary">{t('RESULT_BTN_REPLAY')}</Button>
        <Button variant="contained" disableRipple disableElevation fullWidth onClick={onShare} endIcon={<ShareIcon />} color="secondary" >{t('RESULT_BTN_SHARE')}</Button>


      </Box>}

      {showShareButtons && <Box mt={2}><ShareButtons /></Box>}
    </Box>
  </div>
}