import React from "react"
import { MapContainer, TileLayer, Circle, Polygon, GeoJSON } from 'react-leaflet'
import MapClick from './MapClick';
import MapImageOverlayPerson from './MapImageOverlayPerson';
import MapImageOverlayCar from './MapImageOverlayCar';
import { animated, useSpring } from '@react-spring/web'

import useGeoJsonMask from './../hooks/useGeoJsonMask'

const TILE_LAYER_URL = `https://api.mapbox.com/styles/v1/yaron24/ckz8uefxj000314nob20emj9i/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieWFyb24yNCIsImEiOiJjbDMzMzRkbTIwaDcyM2pwNXk1cTdiMXc4In0.rhzsEigS1CR6kqsImHKB2w`
//const TILE_LAYER_URL = `https://tile.openstreetmap.org/{z}/{x}/{y}.png`;


export default function Map(props) {

  const { onLocationClicked, results, hornData, selectedLocation } = props;
  const [showResults, setShowResults] = React.useState()

  const circleProps = useSpring({
    reset: !!hornData?.metersRadius && !showResults,
    config: { duration: 5000 },
    to: { radius: hornData?.metersRadius, weight: 50 },
    from: { radius: 0, weight: 1 }
  })
  const AnimatedCircle = animated(Circle)

  const { geojson, onClickOutside } = useGeoJsonMask({ path: 'tlv.json' })

  React.useEffect(() => {
    if (results?.polygons) {
      setTimeout(() => { setShowResults(true) }, 5000)
    }
    else {
      setShowResults(false)
    }
  }, [results?.polygons])

  const animatedCircle = React.useMemo(() => {
    if (!selectedLocation || !results) return null;
    return <AnimatedCircle fillColor="#ff5454" color={"#ff5454"} opacity={0.2} center={selectedLocation} weight={circleProps.weight} radius={circleProps.radius} />
  }, [selectedLocation, circleProps.weight, circleProps.radius, results])

  return <MapContainer
    zoomControl={!!onLocationClicked}
    maxBounds={[[32.1593, 34.7327], [32.0116, 34.8630]]} center={[32.066, 34.781]} zoom={14} minZoom={14} scrollWheelZoom={true} style={{ width: '100vw', height: '100vh' }}>
    <MapClick onLocationClicked={onLocationClicked} />

    <TileLayer attribution='&copy; <a href="https://www.mapbox.com">Mapbox</a>' url={TILE_LAYER_URL} />

    {showResults && results?.polygons?.map((polygon, i) => <Polygon positions={[polygon]} key={i} color="#ef2a2a" weight={1} />)}

    {animatedCircle}

    {selectedLocation && <MapImageOverlayCar hornData={hornData} selectedLocation={selectedLocation} key={selectedLocation.lat} />}

    {showResults && <MapImageOverlayPerson results={results} />}

    {geojson && <GeoJSON eventHandlers={{ click: onClickOutside }} data={geojson} style={{ fillOpacity: 0.9, color: '#5D757E', fillColor: '#5D757E' }} />}

  </MapContainer>

}