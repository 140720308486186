export const HORN_TYPES = {
  CAR: 1,
  TRUCK: 2,
  MOTORBIKE: 3
}

export const HORN_TYPES_DATA = {
  [HORN_TYPES.MOTORBIKE]: {weight:1, cursorClass:'cursor-motorbike', metersRadius: 30, image: 'motorbike.svg', audio: 'horn_alt2.mp3'},
  [HORN_TYPES.CAR]: {weight:2, cursorClass:'cursor-car', metersRadius: 60, image: 'car.svg', audio: 'horn.mp3' },
  [HORN_TYPES.TRUCK]: {weight:3, cursorClass:'cursor-truck',  metersRadius: 80, image: 'cargo-truck.svg', audio: 'horn_alt1.mp3'},
 
}

export const GAME_STATE = {
  SPLASH: 0,
  SELECT_HORN: 1,
  SELECT_LOCATION: 2,
  SHOW_RESULT: 3,
  SHOW_ABOUT: 4
}
