import React from "react"
import './App.css';
import "leaflet/dist/leaflet.css";
import Game from "./components/Game";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Noto Sans Hebrew"', 
      'Roboto', 
      'Arial',
      'sans-serif', 
    ].join(','),

    h1: {  fontFamily: [  'Karantina',   'Arial',   'serif',   ].join(',')  },
    h2: {  fontFamily: [  'Karantina',   'Arial',   'serif',   ].join(',')  },
    h3: {  fontFamily: [  'Karantina',   'Arial',   'serif',   ].join(',')  },
    h4: {  fontFamily: [  'Karantina',   'Arial',   'serif',   ].join(',')  },
  },

  palette: {
    primary: {
      main: '#d32f2f'
    },
    secondary: {
      main: '#11468F'
    }
  },
  components: {
    
    MuiButton: {
      styleOverrides: {
      
        contained: {
           
          fontWeight: 'bold',
          borderRadius: 0,
          transform: 'skew(5deg, -2deg)',
          filter: 'drop-shadow(-2px 2px 0 #030404)',
          transition: '.5s',
          '&:hover': {
            transform: 'skew(-2deg, 1deg)',
          },
          '&.alt': {
            transform: 'skew(-5deg, 2deg)',
          }
        },
      },
      defaultProps: {
      
        disableRipple: true, 
      },
    },
  },
});

function App() {
 
  return (
    <ThemeProvider theme={theme}>
      <Game />
      </ThemeProvider>
  );
}

export default App;
