const TEXTS_SRC = {
  HEBREW: {

    CHOOSE_LOCATION: 'בחרו מקום בתל אביב',
    SOUND_ON: 'עם צליל',
    SOUND_OFF: 'בלי צליל',
    CLICK_TO_HONK: 'לחצו כדי לצפור',

    PAGE_TITLE: 'כמה אנשים שומעים את הצפירה שלי?',
    RESULT_LINE_HONK_COULD_ANNOY: 'הצפירה יכלה להטריד',
    RESULT_LINE_INPROGRESS: 'מחשב...',
    RESULT_LINE_RESIDENTS: 'תושבים שגרים בסביבה*',
    RESULT_BTN_HOW: '?איך*',
    RESULT_BTN_REPLAY: 'שוב',
    RESULT_BTN_SHARE: 'שיתוף',

    SELECT_MODE: 'איזה רכב תבחרו?',
    HONK_RANGE_WILL_BE: 'ישמעו את הצפירה בטווח של',
    METERS: 'מטרים',
    CONTINUE: 'המשך',

    INTRO_1: 'לפעמים התנועה לא זזה, ואנחנו ממש רוצים לצפור.',
    INTRO_2: 'אבל יצא לכם לחשוב...',
    INTRO_3: 'כמה תושבים ישמעו את הצפירה שלי?',
    BTN_NEXT: 'כמה?',

    BTN_BACK: 'חזרה',

    FAQ_1_primary: 'איך האתר מחשב כמה אנשים ישמעו את הצפירה?',
    FAQ_1_secondary: 'החישוב מתבצע לפי מספר הקומות בבניינים הנמצאים בקרבת הצפירה. מאחר ולא קיימים נתונים זמינים על מספר התושבים בכל בניין אלא רק על מספר הקומות, ההנחה בבסיס החישוב היא שגרים 3 תושבים בממוצע בכל קומה.',

    FAQ_2_primary: 'עוד גורמים שיכולים להשפיע על התוצאה האמיתית?',
    FAQ_2_secondary: 'עוד גורמים שיכולים להשפיע: מרחק ומיקום הבניין מהצפירה, שעה ביום, כמה אנשים נמצאים ברחוב וכמה אנשים נמצאים בבניין באותו רגע.',


    FAQ_3_primary: 'מאיפה הנתונים?',
    FAQ_3_secondary: <span>
      שכבת הבניינים והנתונים על מספר הקומות בכל בניין לקוחים <a href='https://opendata.tel-aviv.gov.il/he/Pages/home.aspx' target='_blank' rel="noreferrer">מאתר עיריית תל אביב</a>.
    </span>,


    FAQ_4_primary: 'למה יש נתונים רק על תל אביב?',
    FAQ_4_secondary: 'הרעיון לאתר התחיל כאשר רציתי לבדוק כמה אנשים בנוסף אליי שומעים את הצפירות. עיריית תל אביב הנגישה את הנתונים עליהם האתר מתבסס ולצערי לא מצאתי נתונים דומים על כל ישראל.',

    FAQ_5_primary: 'יש לי רעיון איך לשפר את האתר',
    FAQ_5_secondary: <span>שלחו לי אימייל ל<a href='mailto:cars@yaronshemesh.com' target='_blank' rel="noreferrer">cars@yaronshemesh.com</a> או הודעה <a href='https://twitter.com/Yaron24' target='_blank' rel="noreferrer">בטוויטר</a>.</span>,

    FAQ_6_primary: 'מי פיתח את האתר?',
    FAQ_6_secondary: <span>ירון שמש <br />
      תוכלו למצוא עוד פרויקטים <a href='https://yaronshemesh.com' blank='_blank' rel="noreferrer">באתר</a>, ולעקוב אחריי ב<a href='https://twitter.com/Yaron24' blank='_blank'>טוויטר</a>.</span>,


  },
  ENGLISH: {

    CHOOSE_LOCATION: 'Select location in Tel-Aviv',
    SOUND_ON: 'Sound on',
    SOUND_OFF: 'Sound off',
    CLICK_TO_HONK: 'Click to Honk',

    PAGE_TITLE: 'How many residents will hear my honk?',
    RESULT_LINE_HONK_COULD_ANNOY: 'The honk could have annoyed',
    RESULT_LINE_INPROGRESS: 'Calculating...',
    RESULT_LINE_RESIDENTS: 'Residents who live around*',
    RESULT_BTN_HOW: 'How?*',
    RESULT_BTN_REPLAY: 'Again',
    RESULT_BTN_SHARE: 'Share',

    SELECT_MODE: 'Select vehicle',
    HONK_RANGE_WILL_BE: 'Honk will be heard within',
    METERS: 'meters',
    CONTINUE: 'Continue',

    INTRO_1: 'Sometimes traffic is bad, and you just want to honk',
    INTRO_2: 'but did you ever consider...',
    INTRO_3: 'How many residents will hear my honk?',
    BTN_NEXT: 'How many?',
    BTN_BACK: 'Back',

    FAQ_1_primary: "How does the website calculate how many people will hear the siren?",
    FAQ_1_secondary: "The calculation is based on the number of floors in the buildings near the siren. Since there is no available data on the number of residents in each building, only the number of floors, the assumption in the calculation is an average of 3 residents per floor.",
    FAQ_2_primary: "What other factors can affect the actual result?",
    FAQ_2_secondary: "Other factors that can affect it include the distance and location of the building from the siren, the time of day, the number of people on the street, and the number of people in the building at that moment.",
    FAQ_3_primary: "Where does the data come from?",
    FAQ_3_secondary: <span>The data on the buildings and the number of floors in each building are taken from the <a href='https://opendata.tel-aviv.gov.il/he/Pages/home.aspx' target='_blank' rel='noreferrer'>Tel Aviv Municipality website</a></span>,
    FAQ_4_primary: "Why is there data only for Tel Aviv?",
    FAQ_4_secondary: "The idea for the website started when I wanted to see how many people besides me could hear the sirens. Tel Aviv Municipality provides the data on which the website is based, and unfortunately, I couldn't find similar data for all of Israel.",
    FAQ_5_primary: "I have an idea on how to improve the website",
    FAQ_5_secondary: <span>Send me an email at <a href='mailto:cars@yaronshemesh.com' target='_blank' rel='noreferrer'>cars@yaronshemesh.com</a> or a message on <a href='https://twitter.com/Yaron24' target='_blank' rel='noreferrer'>Twitter</a></span>,
    FAQ_6_primary: "Who developed the website?",
    FAQ_6_secondary: <span>Yaron Shemesh <br /> You can find more projects on my <a href='https://yaronshemesh.com' target='_blank' rel='noreferrer'>website</a> and follow me on <a href='https://twitter.com/Yaron24' target='_blank' rel='noreferrer'>Twitter</a>.</span>



  },
}

export default function t(id) {
  const lang = (window.location.search.indexOf('lang=he') > -1) ? 'HEBREW' : 'ENGLISH'
  return TEXTS_SRC[lang][id];
}