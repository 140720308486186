import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import t from '../texts'
const FAQ_COUNT = 6;

const FAQ = "x".repeat(FAQ_COUNT).split("").map((_, i) => ({
  primary: t(`FAQ_${i + 1}_primary`),
  secondary: t(`FAQ_${i + 1}_secondary`),
}))


export default function AboutScreen(props) {


  return <div className={'dialog '} style={{ height: '100%', zIndex: 1750, overflow: 'auto' }} >

    <Box sx={{ maxWidth: 400, margin: '0 auto', padding: '50px 0' }}>
      <Typography variant="h3" className="text-bg" style={{ fontSize: '3rem' }}>כמה תושבים ישמעו את הצפירה שלי?</Typography>

      <Box my={3} sx={{
        '& a': {
          color: '#fff'
        }
      }}>
        {FAQ.map((item, i) => (
          <Accordion key={i} disableGutters className='faq-section'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ background: '#1976d2', color: '#fff' }}
            >
              <Typography style={{ fontWeight: 400 }} ><b>{item.primary}</b></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ background: '#1976d2', color: '#fff', fontWeight: 300 }}>
              <Typography style={{ fontWeight: 300 }}>
                {item.secondary}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>


      <Button onClick={props.onPrev} variant="contained" sx={{ minWidth: 200 }}>{t('BTN_BACK')}</Button>
    </Box>
  </div>
}