import React from "react"
import DialogResult from "./DialogResult";
import DialogSplash from "./DialogSplash";
import DialogInstructions from "./DialogInstructions";
import Map from "./Map";
import AboutScreen from "./AboutScreen";

import { HORN_TYPES, GAME_STATE, HORN_TYPES_DATA } from './../constants.js'

import { calcAffectedBuildings } from './../calcAffectedBuildings'
import DialogSelectHorn from './DialogSelectHorn';

var sounds = {};

function Game() {

  const [selectedHorn, setSelectedHorn] = React.useState(HORN_TYPES.CAR)
  const [gameState, setGameState] = React.useState(GAME_STATE.SPLASH)

  const [selectedLocation, setSelectedLocation] = React.useState()
  const [results, setResults] = React.useState()
  const [isMuted, setIsMuted] = React.useState()

  // preload sound
  React.useEffect(() => {
    if (!selectedHorn) return;
    const horn = HORN_TYPES_DATA[selectedHorn];

    if (!sounds[horn.audio]) {
      sounds[horn.audio] = new Audio(horn.audio);
    }
    sounds['drop.mp3'] = new Audio('drop.mp3');
    sounds['drop2.mp3'] = new Audio('drop2.mp3');

  }, [selectedHorn])

  const onLocationClicked = function (latlng) {
    if (gameState !== GAME_STATE.SELECT_LOCATION) return;

    if (!isMuted) {
      const soundPath = Math.random() > 0.5 ? 'drop.mp3' : 'drop2.mp3';
      sounds[soundPath].play();
    }

    setSelectedLocation(latlng);

  }

  const onStartCalc = async () => {
    setGameState(GAME_STATE.SHOW_RESULT)

    const horn = HORN_TYPES_DATA[selectedHorn];
    if (!isMuted) sounds[horn.audio].play();

    const calcResults = await calcAffectedBuildings(selectedLocation, horn.metersRadius);


    setResults(calcResults);
  }

  const onResetGame = () => {
    setGameState(GAME_STATE.SELECT_HORN)
    setSelectedLocation(null);
    setResults(null);
  }

  const onAbout = () => {
    setGameState(GAME_STATE.SHOW_ABOUT)
  }

  const onResults = () => {
    setGameState(GAME_STATE.SHOW_RESULT)
  }

  const hornData = HORN_TYPES_DATA[selectedHorn];

  const showOverlay = gameState === GAME_STATE.SELECT_HORN || gameState === GAME_STATE.SPLASH

  const languageCode = (window.location.search.indexOf('lang=he') > -1) ? "he" : "en";

  return (
    <div className={`lang-${languageCode}`}>
      <Map results={results}
        selectedLocation={selectedLocation}
        onLocationClicked={gameState === GAME_STATE.SELECT_LOCATION ? onLocationClicked : null}
        hornData={hornData} />


      {showOverlay && <div style={{
        position: 'absolute', zIndex: 1100,
        background: `#222222 no-repeat center center`,

        backgroundImage: `url('assets/bg-traffic-tlv-op.jpg')`,
        backgroundSize: 'cover',
        filter: 'brightness(0.5)',

        width: '100vw', height: '100vh', top: 0, left: 0
      }}></div>}

      {gameState === GAME_STATE.SPLASH && <DialogSplash onNext={() => { setGameState(GAME_STATE.SELECT_HORN) }} />}

      {gameState === GAME_STATE.SELECT_HORN && <DialogSelectHorn setSelectedHorn={setSelectedHorn} selectedHorn={selectedHorn} onNext={() => { setGameState(GAME_STATE.SELECT_LOCATION) }} />}

      {gameState === GAME_STATE.SELECT_LOCATION && <DialogInstructions isMuted={isMuted} setIsMuted={setIsMuted} onLocationSelected={onStartCalc} selectedLocation={selectedLocation} />}

      {(gameState === GAME_STATE.SHOW_RESULT || gameState === GAME_STATE.SHOW_ABOUT) && <DialogResult results={results} onPrev={onResetGame} onAbout={onAbout} />}

      {gameState === GAME_STATE.SHOW_ABOUT && <div style={{
        position: 'absolute', zIndex: 1650,
        background: `rgba(0,0,0,0.5)`,
        backdropFilter: 'blur(10px)',
        width: '100vw', height: '100vh', top: 0, left: 0
      }}></div>}

      {gameState === GAME_STATE.SHOW_ABOUT && <AboutScreen onPrev={onResults} onAbout={onAbout} />}

    </div>
  );
}

export default Game;
