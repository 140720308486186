
import {  ImageOverlay } from 'react-leaflet'
  
export default function MapImageOverlayCar(props) {

  const { hornData, selectedLocation } = props;
 
  const getImageOverlayProps = ( ) => {

    const overlayProps = {}
  
    const p = [selectedLocation.lat, selectedLocation.lng];
    const meters = 10 ;
    const x = meters / (111.32 * 1000);
    const y = meters / (40075 * (Math.cos(x) / 360));
      
    overlayProps.bounds = [
      [p[0]-x, p[1]-y],
      [p[0]+x, p[1]-y],
      [p[0]+x, p[1]+y],
      [p[0]-x, p[1]+y], 
    ]
 
    overlayProps.url = hornData.image;
    
    overlayProps.zIndex = 9998
 
 
    return overlayProps;
  } 
   
 
  return    <ImageOverlay {...getImageOverlayProps()} /> 
}