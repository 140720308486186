
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import t from '../texts.js'

export default function DialogInstructions(props) {

  const { isMuted, setIsMuted, selectedLocation } = props;

  const onLocationSelected = () => {
    props.onLocationSelected()
  }

  return <><div className={'dialog  '} style={{ padding: '20px 0', maxWidth: '100vw' }}  >

    <Typography variant="h2" className="text-bg" style={{ fontSize: '3em', fontWeight: 900, padding: 20 }}>{t('CHOOSE_LOCATION')}</Typography>

    <Box>
      <Button style={{ direction: 'ltr', marginTop: 10, transform: 'none' }}
        variant="contained"
        color="inherit"
        onClick={e => { setIsMuted(!isMuted) }}
        size="small" endIcon={isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />} >
        {isMuted ? t('SOUND_OFF') : t('SOUND_ON')}
      </Button>
    </Box>
  </div>
    <Box sx={{
      position: 'fixed',
      transition: '1s',
      bottom: selectedLocation ? '8vh' : '-300px',
      left: 0,
      right: 0,

      margin: '0 auto',
      width: '100%',
      maxWidth: '90vw',
      zIndex: 9999,
      display: 'flex',
      justifyContent: 'center'
    }}

    >
      <Button variant="contained"
        className="btn-start-horn"
        sx={{
          paddingRight: 5,
          paddingLeft: 5,
          fontSize: '4vh'
        }}
        onClick={onLocationSelected}
      >{t('CLICK_TO_HONK')}</Button>




    </Box>
  </>
}