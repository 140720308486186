 
import { sumBy } from 'lodash' 
 
const ESTIMATE_PER_FLOOR = 3;

const BASE_ENDPOINT = 'https://gisn.tel-aviv.gov.il/arcgis/rest/services/WM/IView2WM/MapServer/513/query?where=1%3D1&text=&objectIds=&time=&relationParam=&outFields=ms_komot&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=4326&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&units=esriSRUnit_Kilometer'



export const calcAffectedBuildings = async function (latlng_WGS84, metersRadius) {
 
  let result;

  try {
    result = await calcAffectedBuildingsRemote(latlng_WGS84, metersRadius);
  }
  catch (exception) {
    console.warn("Failed fetching from TLV API, fallback to local file", result, exception)    
  }


  if (!result) {
    try {
      result = await calcAffectedBuildingsLocal(latlng_WGS84, metersRadius);
    }
    catch (exception) {
      console.warn("Failed fetching from local file", exception)
      
    }
  }

  return result;
  
}
 
export const calcAffectedBuildingsLocal = async function (latlng_WGS84, metersRadius ) {
  
  // Approx length
  const metersToLat = metersRadius / (111.32 * 1000);
  //const metersToLon = metersRadius / (40075 * (Math.cos(metersLat) / 360));
  const BUILDING_LENGTH = 0.00005;

  const response = await fetch('buildings.json');
  const buildings = await response.json(); 

  const buildingInRadius = buildings.filter(b => check_a_point(b[1], b[0], latlng_WGS84.lat, latlng_WGS84.lng, metersToLat));
  const totalFloors = sumBy(buildingInRadius, f=>f[2]);

  const polygons = buildingInRadius.map(p => ([
    [p[1]-BUILDING_LENGTH, p[0]-BUILDING_LENGTH],
    [p[1]+BUILDING_LENGTH, p[0]-BUILDING_LENGTH],
    [p[1]+BUILDING_LENGTH, p[0]+BUILDING_LENGTH],
    [p[1]-BUILDING_LENGTH, p[0]+BUILDING_LENGTH],
    [p[1]-BUILDING_LENGTH, p[0]-BUILDING_LENGTH],
  ]))
 
  return {
    affectedEstimate: totalFloors * ESTIMATE_PER_FLOOR,
    polygons
  }

}

export const calcAffectedBuildingsRemote = async function (latlng_WGS84, metersRadius) {
    
  const result = await getBuildings(latlng_WGS84, metersRadius);
    
  const polygons = result.features.map(p => p['geometry']['rings'][0].map(c => [c[1], c[0]]));
  const totalFloors = result.features.map(p=>p['attributes']['ms_komot']).reduce((prev, acc) => prev + acc, 0);

  return {
    affectedEstimate: totalFloors * ESTIMATE_PER_FLOOR,
    polygons
  }
}


const getBuildings = async (latlng_WGS84, metersRadius) => {
  // query api
  const radiusKm = (metersRadius/1000) || (30/1000);
  
  const response = await fetch(`${BASE_ENDPOINT}&distance=${radiusKm}&geometry=%7B${latlng_WGS84.lng},${latlng_WGS84.lat}%7D`);
  const result = await response.json();
 
  return result;
}

function check_a_point(a, b, x, y, r) {
  var dist_points = (a - x) * (a - x) + (b - y) * (b - y);
  r *= r;
  if (dist_points < r) {
      return true;
  }
  return false;
}


 