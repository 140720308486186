
import {  ImageOverlay } from 'react-leaflet'

import * as L from "leaflet";
import {shuffle, range} from 'lodash'
 
const images = [
'001-dissatisfaction.svg',
'002-woman.svg',
'003-angry.svg',
'004-obscene.svg',
'005-anger.svg',
'006-angry-1.svg',
'007-anxiety.svg',
'008-angry-2.svg',
'009-man.svg',
'010-monkey.svg',
'011-bear.svg',
'012-sad.svg',
'013-kitty.svg',
'014-girl.svg',
'015-jealous.svg',
'016-angry-face.svg', 
'018-boy.svg',
'019-annoying.svg',
 
'021-cursing.svg',
'022-dog.svg',
'023-woman-1.svg',
'024-boy-1.svg',
'025-irritable.svg',
'026-angry-3.svg',
'027-angry-4.svg',
]

export default function MapImageOverlayPerson(props) {

  const { results } = props; 
  const getImageOverlayProps = (polygon, i, imageId) => {

    const overlayProps = {}

    const latlng = L.polygon(polygon).getBounds().getCenter();
    
    const p = [latlng.lng, latlng.lat];
    const meters = 8;
    const x = meters / (111.32 * 1000);
    const y = meters / (40075 * (Math.cos(x) / 360));
    
    overlayProps.bounds = [
      [p[1]-x, p[0]-y],
      [p[1]+x, p[0]-y],
      [p[1]+x, p[0]+y],
      [p[1]-x, p[0]+y], 
    ] 
 
    overlayProps.url = `assets/${images[imageId]}`
    
    overlayProps.zIndex = 9999

    return overlayProps;
  } 
  
 
  if (!results?.polygons) return null;

  const LIMIT = Math.min(results?.affectedEstimate, 4);
  const imagesIds = shuffle(range(0, images.length - 1)).slice(0, LIMIT);
  
  return results.polygons.filter((v, i) => i % Math.ceil(results.polygons.length / LIMIT) === 0).map((polygon, i) =>
    <ImageOverlay
      key={i}
      alt="Angry resident"
      className="animated-person"
      {...getImageOverlayProps(polygon, i, imagesIds[i])}
    />)
}