import { useState, useEffect } from 'react'
import { DomEvent } from 'leaflet'

export default function useGeoJsonMask({ path }) {

  const [geojson, setGeojson] = useState();

  useEffect(() => {
    async function getJSON() {
      try {
        const response = await fetch(path);
        const geoJson = await response.json();
        geoJson.geometry.coordinates.unshift([[30, 30], [40, 30], [40, 40], [30, 40]]);
        setGeojson(geoJson);
      }
      catch (err) {
        console.warn("Failed loading GeoJson", err)
      }
    }

    getJSON();
  }, [])

  const onClickOutside = (e) => {
    DomEvent.stopPropagation(e);
    return false;
  }

  return { geojson, onClickOutside }
}